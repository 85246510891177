import { extendTheme } from '@chakra-ui/react';

const theme = {
  styles: {
    global: {
      // '@media (-webkit-device-pixel-ratio: 1.5)': { '*': { transform: 'scale(0.75)' } },
      body: {
        background: 'gray.50',
        color: 'gray.900',
        letterSpacing: 0.3,
        lineHeight: 1.75,
      },
      '.blog-text': {
        p: {
          marginY: 4,
        },
        ul: {
          paddingX: 8,
          marginY: 4,
        },
        ol: {
          counterReset: 'list',
          margin: 0,
          paddingX: 8,
          marginY: 4,
          '> li': {
            listStyle: 'none',
            position: 'relative',
            '&:before': {
              counterIncrement: 'list',
              content: 'counter(list, lower-alpha) ") "',
              position: 'absolute',
              left: '-1.4em',
            },
          },
        },
        h2: {
          fontFamily: 'heading',
          fontWeight: 500,
          marginBottom: 4,
          fontSize: 'xl',
        },
        a: {
          color: 'secondary',
          textDecoration: 'none',
          _hover: {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
  fonts: {
    heading: '"Vollkorn", serif',
    body: '"Noto Sans", sans-serif',
  },
  semanticTokens: {
    colors: {
      primary: '#7209b7',
      primaryLight: '#560bad',
      secondary: '#f54952',
      background: 'gray.50',
      backgroundLight: '#fff',
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'heading',
        fontWeight: 500,
      },
      variants: {
        secondary: {
          fontFamily: 'heading',
        },
        tertiary: {
          fontFamily: 'body',
        },
      },
    },
    Button: {
      baseStyle: {
        textTransform: 'uppercase',
        fontWeight: 400,
        fontFamily: 'body',
        transition: 'all .2s ease-in-out',
        boxShadow: 'md',
        _hover: {
          boxShadow: 'sm'
        }
      },
      variants: {
        primary: {
          background: 'primaryLight',
          color: 'whiteAlpha.900',
          _hover: {
            '&:not(:disabled)': { background: 'primary' },
            '&:disabled': { background: 'primary' },
          },
        },
        secondary: {
          background: 'transparent',
          color: '#d9d9d9',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: '#d9d9d9',
          _hover: {
            background: 'whiteAlpha.700',
            color: 'black',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 600,
        textTransform: 'uppercase',
      },
    },
    Link: {
      baseStyle: {
        color: 'secondary',
        textDecoration: 'none',
        _hover: {
          textDecoration: 'none',
          color: 'secondary',
        },
      },
    },
    Text: {
      baseStyle: {
        marginY: 4,
      },
    },
    Input: {
      variants: {
        contact: {
          backgroundColor: 'transparent',
          appearance: 'none',
          borderColor: 'secondary',
        },
      },
    },
  },
};

export default extendTheme(theme);
