exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-clanky-markdown-remark-frontmatter-path-tsx": () => import("./../../../src/pages/clanky/{markdownRemark.frontmatter__path}.tsx" /* webpackChunkName: "component---src-pages-clanky-markdown-remark-frontmatter-path-tsx" */),
  "component---src-pages-clanky-tsx": () => import("./../../../src/pages/clanky.tsx" /* webpackChunkName: "component---src-pages-clanky-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-ochrana-osobnych-udajov-tsx": () => import("./../../../src/pages/ochrana-osobnych-udajov.tsx" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-tsx" */),
  "component---src-pages-pravne-sluzby-fuzie-a-akvizicie-tsx": () => import("./../../../src/pages/pravne-sluzby/fuzie-a-akvizicie.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-fuzie-a-akvizicie-tsx" */),
  "component---src-pages-pravne-sluzby-obchodne-pravo-tsx": () => import("./../../../src/pages/pravne-sluzby/obchodne-pravo.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-obchodne-pravo-tsx" */),
  "component---src-pages-pravne-sluzby-obcianske-pravo-tsx": () => import("./../../../src/pages/pravne-sluzby/obcianske-pravo.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-obcianske-pravo-tsx" */),
  "component---src-pages-pravne-sluzby-pravo-nehnutelnosti-tsx": () => import("./../../../src/pages/pravne-sluzby/pravo-nehnutelnosti.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-pravo-nehnutelnosti-tsx" */),
  "component---src-pages-pravne-sluzby-predaj-spolocnosti-tsx": () => import("./../../../src/pages/pravne-sluzby/predaj-spolocnosti.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-predaj-spolocnosti-tsx" */),
  "component---src-pages-pravne-sluzby-rodinne-pravo-tsx": () => import("./../../../src/pages/pravne-sluzby/rodinne-pravo.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-rodinne-pravo-tsx" */),
  "component---src-pages-pravne-sluzby-sporova-agenda-tsx": () => import("./../../../src/pages/pravne-sluzby/sporova-agenda.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-sporova-agenda-tsx" */),
  "component---src-pages-pravne-sluzby-trestne-pravo-tsx": () => import("./../../../src/pages/pravne-sluzby/trestne-pravo.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-trestne-pravo-tsx" */),
  "component---src-pages-pravne-sluzby-tsx": () => import("./../../../src/pages/pravne-sluzby.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-tsx" */),
  "component---src-pages-pravne-sluzby-zapis-do-registrov-tsx": () => import("./../../../src/pages/pravne-sluzby/zapis-do-registrov.tsx" /* webpackChunkName: "component---src-pages-pravne-sluzby-zapis-do-registrov-tsx" */)
}

